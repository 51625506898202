import { createEnvironmentApi, Environment } from "./environment.schema";

const coreHost = "https://internal.api.dev.qundo.net";
const aiHost = "https://internal.api.dev.qundo.net/ai-wrapper";
const envTile = "<b style='color:red;'> (DEV: qundo.net)</b>";

export const environment: Environment = {
  api: createEnvironmentApi(coreHost, aiHost),
  envTile: envTile,
  client: {
    clientName: "admin_client",
    clientSecret: "client_password"
  },
  production: true
};
